.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

button, input {
    outline: none;
}

button.big {
    color: white;
    background-color: crimson;
    width: 5rem;
    height: 5rem;
    border-radius: 10rem;
    margin: 1rem;
    font-weight: bold;
}

.song-list code {
    display: block;
}

.song-list button {
    color: white;
    background-color: cornflowerblue;
}
