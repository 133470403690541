html, body, #root {
    margin: 0;
    height: 100%;
}
body {
  font-family: sans-serif;
}

.App {
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
}

button, input {
    outline: none;
}

button.big {
    color: white;
    background-color: crimson;
    width: 5rem;
    height: 5rem;
    border-radius: 10rem;
    margin: 1rem;
    font-weight: bold;
}

.song-list code {
    display: block;
}

.song-list button {
    color: white;
    background-color: cornflowerblue;
}

